import React from 'react'
import { Container } from '@mui/material'
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


function UpcomingEvents() {
    return (
        <Container sx={{mt:3}}>
        <h3>upcoming events</h3>


        </Container>
    )
}

export default UpcomingEvents
