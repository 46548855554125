import React from "react";
import styles from "./YourTable.module.css";

import Avatar from "@mui/material/Avatar";
import { sittingTableData } from "../pages/YourEvent/sittingTableData";
import { BiBorderRadius } from "react-icons/bi";
import { Margin, Padding } from "@mui/icons-material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
const guestIndex = [1,2,3,4,5,6,7,8,9,10]
const rotateAngle = [0, 36, 72, 108,144,180, 216, 252, 288, 324]
const currentIndex = 0

function setIndex (i){
  return guestIndex[i]
  const currentIndex = 2
}
function YourTable({}) {
  // const isSeated = item.isSeated;
  return (
    <div
      className={styles.circleContainer}
      style={{
        backgroundColor: "white",
        position: "relative",
        width: "280px",
        height: "280px",
        borderRadius: "50%",
        padding: "0",
        margin: "12px auto 0",
        boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.4)",
        border: "1px solid grey",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: "300",
          display: "flex",
          width: "100%",
          height: "100%",
          padding: "40px",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: "1",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: "12px",
            }}
          >
            Sponsor:
          </div>
          <img src="./logos/ipsum.png" width="120px" />
          <div
            style={{
              marginTop:"8px"
            }}
          >
            Table no.11
          </div>
        </div>
      </div>
      {sittingTableData.map((item) => (
        <div isSeated={item.isSeated}>
          <Avatar
            alt={item.firstName + " " + item.lastName}
            src={"avatars/" + item.avatar}
            className={styles.avatar}
            sx={{
              marginTop: "110px",
              marginLeft: "110px",
              width: "60px",
              height: "60px",
              position: "absolute",
              transform:
                "rotate(" +
                rotateAngle[item.id] +
                "deg) translate(140px) rotate(-" +
                rotateAngle[item.id] +
                "deg)",
              border: "2px solid white",
              boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.4)",
            }}
          ></Avatar>
        </div>
      ))}
    </div>
  );
}

export default YourTable;
