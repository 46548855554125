import * as React from 'react';
// import './Events.module.css';
import { red, green, blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const Sample = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
        backgroundColor: red[500],
    },
    [theme.breakpoints.up('md')]: {
        backgroundColor: blue[500],
    },
    [theme.breakpoints.up('lg')]: {
        backgroundColor: green[500],
    }
}));


function Events() {
    return (
        <Sample>
            <Typography>down(md): red</Typography>
            <Typography>up(md): blue</Typography>
            <Typography>up(lg): green</Typography>
        </Sample>

    )
}

export default Events;