const items = [
  {
    title: "5pm",
    cardTitle: "VIP Reception",
    media: {
      name: "reception",
      source: {
        url: "./reception.jpg",
      },
      type: "IMAGE",
    },
    cardSubtitle:
      "On the Lido Deck - By separate invitation only.",
    cardDetailedText: `I'm thrilled you will be joining us for the Gala. I feel so much pride in what all of you have been able to make happen in such a short amount of time. Tonight wouldn't be possible without you. Our amazing marketing interns took to the campus to make a short film that brings to life all that our organization has become. Please enjoy and I look forward to seeing  you tonight.`,
  },


  {
    title: "6pm",
    cardTitle: "Silent Auction",
    cardSubtitle: `Auction items are on display in the Annex`,
    media: {
      type: "IMAGE",
      source: {
        url: "./silent-auction.jpg",
      },
    },
    cardDetailedText: `Peruse the gallery of more than 100 items available for bid. If you're lucky enough to win the Grand Prize, you'll spend 7 days on the Riviera, soaking up the sun by day, feasting on the finest cuisine at night.`,
  },


  {
    title: "7pm",
    cardTitle: "Dinner",
    media: {
      type: "IMAGE",
      source: {
        url: "./gala-dinner.jpg",
      },
    },
    cardSubtitle: `Assigned Seating in the Main Ballroom.`,
    cardDetailedText: `A gourmet three course dinner awaits. Plated salads, followed by your chosen entree, finished with sorbet.`,
  },


  {
    title: "8pm",
    cardTitle: "Paddle Raise",
    media: {
      type: "IMAGE",
      source: {
        url: "./liveauction.jpg",
      },
    },
    cardSubtitle: `Live auction with your MC, David Ono, newscaster for KABC`,
    cardDetailedText: `Exciting live auction bid to support education for underrepresented youth in the local area.`,
  },
  {
    title: "9pm",
    cardTitle: 'Preview of the exhibit, "Rain on Me" ',
    media: {
      type: "IMAGE",
      source: {
        url: "./rain.jpg",
      },
    },
    cardSubtitle: `Honda Gallery`,
    cardDetailedText: `A sneak peak of "Rain on Me," the immersive exhibit by the McGillivray Group, renowned for their emotional treatment  of sea life's struggles and triumphs.`,
  },
  {
    title: "10pm",
    cardTitle: 'Dance the night away" ',
    media: {
      type: "IMAGE",
      source: {
        url: "./rain.jpg",
      },
    },
    cardSubtitle: `Play Courtyard`,
    cardDetailedText: `Kick off those Sunday shoes! We have the pleasure of welcoming the Paul Jones Band, nominated for the HIMA awards in 2016. `,
  },
  {
    title: "11pm",
    cardTitle: 'Before you leave..." ',
    media: {
      type: "IMAGE",
      source: {
        url: "./rain.jpg",
      },
    },
    cardSubtitle: `Play Courtyard`,
    cardDetailedText: `If you parked with our Valets: Remember to top at the Parking Kiosk for validation before you leave.<br>If you won the silent auction: Remember to visit the shop to pick up your item or make arrangements to have your itms mailed to you.`,
  }
];

export default items;