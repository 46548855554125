// import { useRef } from 'react';
// import { Route, Routes } from "react-router-dom"
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import YourEvent from "./pages/YourEvent/YourEvent";
import EventTimeline from "./pages/EventTimeline/EventTimeline";
import AboutUs from "./pages/AboutUs";
import Profile from "./pages/Profile/Profile";
import Layout from "./pages/Layout";

import NotFound from "./pages/NotFound/NotFound";
import Events from "./pages/Events";
import PlayGround from "./pages/PlayGround";
import Accessibility from "./pages/Accessibility";
import ProfieEdit from "./pages/Profile/ProfileEdit";
import UpcomingEvents from "./pages/UpcomingEvents";
import UsefulInfo from "./pages/UsefulInfo";
import TableCircle from "./component/TableCircle";
import TableCircleTest from "./component/TableCircleTest";
const router = createBrowserRouter([
  {
    element: <Layout />,

    children: [
      {
        path: "/",
        element: <Profile />,
      },
      {
        path: "/event-timeline",
        element: <EventTimeline />,
        errorElement: <NotFound />,
      },
      {
        path: "/your-event",
        element: <YourEvent />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/accessibility",
        element: <Accessibility />,
      },

      {
        path: "/up-coming-events",
        element: <UpcomingEvents />,
      },

      {
        path: "/useful-info",
        element: <UsefulInfo />,
      },

      {
        path: "/playground",
        element: <PlayGround />,
      },

      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/profile-edit",
        element: <ProfieEdit />,
      },
      {
        path: "/table-circle",
        element: <TableCircle />,
      },
      {
        path: "/table-circle-test",
        element: <TableCircleTest />,
      }

      // {TableCircleTest
      //   path: '/layout',
      //   element: <Layout />,
      // }
    ],
  },
]);
function App() {
  return (
    // <ThemeProvider  theme={theme}>
    <RouterProvider router={router} />
    // </ThemeProvider>
  );
}

export default App;
