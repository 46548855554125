


export const sittingTableData = [
  {
    id: 0,
    preferredName:'',
    firstName: 'Carlos',
    middleName:'',
    lastName: "Chiyoda",
    avatar: "avatar-carlos.jpg",
    isSeated: true,
    work: "IBM",
    position: "graphic designer",
    aboutMe: "Aute pariatur laborum pariatur qui. Eiusmod ut velit labore laboris ut cupidatat pariatur consequat consectetur dolore. ",

    social: [
      {
        media: 'instagram',
        address: "https://www.instagram.com/cchiyoda/"
      },
      {
        media: 'linkedin',
        address: "https://www.linkedin.com/in/cchiyoda/"
      },
      {
        media: 'facebook',
        address: "https://www.facebook.com/carlos.chiyoda/"
      }
    ]
  },
  {
    id: 1,
    preferredName:'',
    firstName: 'Jesse',
    middleName:'',
    lastName: "Martin",
    avatar: "avatar-jesse.jpg",
    isSeated: true,
    work: "Ipsum",
    position: "software developer",
    aboutMe: "Sit velit ex laborum id. Consectetur nostrud labore laboris ipsum ut non dolor incididunt nisi. Mollit magna aliquip occaecat nisi irure ipsum est minim eu in amet veniam dolore.",
    social: [
      {
        media: 'instagram',
        address: "https://www.instagram.com/jk/"
      },
      {
        media: 'linkedin',
        address: "https://www.linkedin.com/in/jessehmartin/"
      }

    ]
  },
  {
    id: 2,
    preferredName:'',
    firstName: 'Cynthia',
    middleName:'',
    lastName: "Mikimoto",
    avatar: "avatar-cynthia.jpg",
    isSeated: true,
    work: "Caltech",
    position: "Associate Director of Prospect Management",
    aboutMe: "Laborum et minim elit enim consequat. Eu ipsum reprehenderit reprehenderit exercitation nisi. Id ut dolore sint et laborum quis. Excepteur non eu proident elit reprehenderit duis sunt elit reprehenderit aliquip ea id incididunt. Amet duis minim aliqua voluptate occaecat fugiat nostrud sit Lorem amet qui. Anim sint nulla ullamco ex dolore ipsum nostrud.",
    social: [
      {
        media: 'instagram',
        address: "https://www.instagram.com/jk/"
      },
      {
        media: 'linkedin',
        address: "https://www.linkedin.com/in/cynthiamikimoto/"
      }
    ]
  }, 
  {
    id: 3,
    preferredName:'',
    firstName: 'Mary',
    middleName:'',
    lastName: "Manner",
    avatar: "",
    seated: true,
    work: "Ipsum",
    position: "",
    aboutMe: "",
    social: [
      {
        media: '',
        address: ''
      }
    ]
  },
   
  {
    id: 4,
    preferredName:'',
    firstName: 'Rex',
    middleName:'',
    lastName: "Ingram",
    avatar: "avatar-rex-ingram.jpg",
    isSeated: false,
    work: "Fidelity",
    position: "",
    aboutMe: "Fugiat nisi ut reprehenderit aliquip commodo tempor sit minim.",
    social: [
      {
        media: '',
        address: ''
      }
    ]
  },
   
  {
    id: 5,
    preferredName:'',
    firstName: 'Briggs',
    middleName:'',
    lastName: "Arellano",
    avatar: "",
    isSeated: false,
    work: "IBM",
    position: "",
    aboutMe: "",
    social: [
      {
        media: '',
        address: ''
      }
    ]
  },
   

   
  {
    id: 6,
    preferredName:'',
    firstName: 'Zion',
    middleName:'',
    lastName: "Durham",
    avatar: "",
    isSeated: false,
    work: "",
    position: "",
    aboutMe: "",
    social: [
      {
        media: 'instagram',
        address: "https://www.instagram.com/cchiyoda/"
      }
    ]
  },
   
  {
    id: 7,
    preferredName:'',
    preferredName: 'JohnnyB',
    firstName: 'John',
    middleName:'',
    middleName: 'Albert',
    lastName: "Becker",
    avatar: "avatar-john.jpg",
    isSeated: false,
    work: "IBM",
    position: "",
    aboutMe: "",
    social: [
      {
        media: '',
        address: ''
      }
    ]
  },
   
  {
    id: 8,
    preferredName:'',
    firstName: 'Fiona',
    middleName:'',
    lastName: "Duke",
    avatar: "avatar-fiona-duke.jpg",
    isSeated: true,
    work: "Ipsum",
    position: "",
    aboutMe: "",
    social: [
      {
        media: '',
        address: ''
      }
    ]
  },
   
  {
    id: 9,
    preferredName:'',
    firstName: 'joana',
    middleName:'LongMiddleName',
    lastName: "Longname",
    avatar: "avatar-joana.jpg",
    isSeated: true,
    work: "",
    position: "Self-employed",
    aboutMe: "Sit nulla sint exercitation consequat ipsum incididunt anim sunt laboris dolore sunt ut dolore nostrud.Veniam proident adipisicing sit enim cupidatat irure dolore. Pariatur ad nisi pariatur dolor qui laboris cillum sint. Eu sunt eu duis cillum. Deserunt sit proident sunt sit.",
    social: [
      {
        media: '',
        address: ''
      }
    ]
  },
  

];

