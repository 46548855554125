import * as React from 'react';
import './EventTimeline.module.css';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';

import { Chrono } from "react-chrono";
import data from "./data";
import { HelpOutline } from '@mui/icons-material';
import styles from './EventTimeline.module.css';

function EventTimeline() {
    return (

    //  <div> hello</div>
        <div className = {styles.TimeLineContainer}>
        <Chrono
          items={data}
          mode="VERTICAL"
          cardHeight={300}
          cardWidth={650}
          mediaHeight={300}
          scrollable={{ scrollbar: false }}
          contentDetailsHeight={100}
          fontSizes={{
            title: "1.25rem"
          }}
 
        />

          
        
      // </div>
      
    )
}

export default EventTimeline;