import React from 'react'
import { Container } from '@mui/material'

function UsefulInfo() {
    return (
        <Container>
            <h2>useful info</h2>
            <ul>
                <li>Organizer info, name, contact (display card?)</li>
                <li></li>
                <li></li>
                <li>Wifi password for the event or no wifi available</li>

            </ul>
            <h3>    Before you leave</h3>
            <ul>
            
                <li>Validate your parking ticket</li>
                <li>Leave feedback about the event is possible</li>


            </ul>

            !pricingtabl
        </Container>
    )
}

export default UsefulInfo
