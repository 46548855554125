import React from "react";
import styles from "./TableCircle.module.css";
import "./TableCircle.css";
import { sittingTableData } from "../pages/YourEvent/sittingTableData";

function TableCircle() {
  return (
<div class="container-fluid">
      <div class="circle_container col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 col-xs-12">
         <img class="transparent_square" src="http://i.stack.imgur.com/5Y4F4.jpg" width="2" height="2" />
         <div class="central_text text-center">
            <h3 id="item_title">Test</h3>
            <h5 id="item_description"></h5>
         </div>
         <div class="moon_container moon1"><a href=""><img class="moon moon1" alt="item1" src="https://tinyurl.com/ybq7qpj9" /></a></div>
        
        <div class="moon_container moon2"><a href=""><img class="moon moon2" alt="item2" src="https://tinyurl.com/ybq7qpj9" /></a></div>
       
       <div class="moon_container moon3"><a href=""><img class="moon moon3" alt="item3" src="https://tinyurl.com/ybq7qpj9" /></a></div>
         
         <div class="moon_container moon4"><a href=""><img class="moon moon4" alt="item4" src="https://tinyurl.com/ybq7qpj9" /></a></div>
         
         <div class="moon_container moon5" ><a href=""><img class="moon moon5" alt="item5" src="https://tinyurl.com/ybq7qpj9" /></a></div>
         
         <div class="moon_container moon6"><a href=""><img class="moon moon6" alt="item6" src="https://tinyurl.com/ybq7qpj9" /></a></div>
      </div>
   </div>
  );
}

export default TableCircle;
