import React from 'react'
import { Container } from '@mui/material'

function AboutUs() {
    return (
        <Container sx={{mt: 4}}>about us</Container>
    )
}

export default AboutUs
