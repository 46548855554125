import React from 'react'
import { Container } from "@mui/material";
import { Button } from '@mui/material'

function Accessibility() {
    return (
        <Container>

                <ul>
                    <li>show map displaying where the accessible ramps or short steps are.</li> 
                    <li>show rooms that are accessible only by steps (how many steps: some people can use few steps)</li>
                    <li>Accessible restrooms in the building (some have all the handles near the toilets, rope to call for assistance, but the door still uses a knob to open</li>
                    <li>is the stage accessible?</li>
                    <li>are the elevators accessible?</li>
                    <li>request assistance button  </li>
                </ul>
                <Button variant="contained">Request Assistance</Button>

        </Container>
    )
}

export default Accessibility
