import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { LeftNavMenuData } from "./LeftNavMenuData";
// import { hasChildren } from "./utils";
import styles from './LeftNav.module.css';

import './LeftNav.css';
import { Typography } from "@mui/material";

 function LeftNav() {

  return (
    <div className= {styles.LeftNavContainer}>
      {LeftNavMenuData.map((item) => (
        <div className={styles.MenuContainer}>
          <NavLink
          key={item.title} 
          to={item.path} 
          className={({isActive}) => {
            return isActive ? styles.SideMenuActive : ""; 
          }}
          >
             <div> {item.icon}</div>
         
             {item.title}
          </NavLink>
        </div>
      ))}

    </div>

  )
}
export default LeftNav;
