import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div>
      Not found Page
      <Link to="/">Home</Link>
    </div>
  );
}

export default NotFound;
