import * as React from "react";
import styles from "./YourEvent.module.css";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { sittingTableData } from "./sittingTableData";
import Masonry from "@mui/lab/Masonry";
import GuestCard from "../../component/GuestCard";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import YourTable from "../../component/YourTable";
import Grid from "@mui/material/Unstable_Grid2";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Box from "@mui/material/Box";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Paper from '@mui/material/Paper';



function YourEvent() {
  return (
    <div className={styles.YourEvent}>
      <Container
        sx={{
          pt: 2,
          pb: 1,
          fontWeight: 700,
        }}
      >
        <Card
          className={styles.bannerCard}
          sx={{
            p: 2,
            position: "relative",
            overflow: "visible",
            backgroundColor: "rgb(255, 255, 255)",
            backgroundImage: "linear-gradient(rgb(255, 255, 255) 10%, rgb(242, 229, 229) 90% )"
          }}
        >
          <Typography component="h1" variant="h5" sx={{
            pl:1, pb:1, pt: 1
          }}>
            Crimson Spring Event
          </Typography>
          <CardContent
            sx={{
              mt: 0,
              mr: "40px",
              pl: 1,
              pt: 1,
              // backgroundColor: "rgba(255, 255, 255, 0.5)",
              backgroundImage: "linear-gradient(to right, rgba(255, 255, 255, .6) 10%,  rgba(255, 255, 255, 0.01) 90% )"
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 3,
              }}
            >
              <CalendarMonthIcon
                sx={{
                  mt: 0.3,
                  color: "gray",
                }}
              />
              <Typography
                sx={{
                  ml: 1,
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                Friday, April 12, 2024, 6PM - 11PM
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "top",
                mb: 3,
              }}
            >
              <LocationOnIcon
                sx={{
                  mt: 0.3,
                  color: "gray",
                }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  ml: 1,
                }}
              >
                <div>
                  The Westin Bonaventure Hotel & Suites
                  <br />
                  West Ballroom
                </div>
                <div>404 South Figueroa Street</div>
                <div>Los Angeles, CA</div>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 3,
              }}
            >
              <LocalPhoneIcon
                sx={{
                  mt: 0.3,
                  color: "gray",
                }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  ml: 1,
                }}
              >
                (213) 624-1000
              </Typography>
            </Box>
          </CardContent>

          <img src="./crimsonflower.png" className={styles.flowerImage} />
        </Card>
      </Container>
      <Container sx={{
        mt:4,
        }}>
        <Card sx={{
          flexGrow: 1,
          pl:2
        }}>
          <Grid
            container
            columnSpacing={{ xs: 1, sm: 4, md: 4 }}
            rowSpacing={4}
            sx={{
              marginTop: "12px",
              marginBottom: "20px",

            }}
          >
            <Grid xs={12} md={6}>
              <Typography
                align="center"
                sx={{
                  fontWeight: 600,
                  fontSize: 20,
                  mb:2

                }}
              >
                Where Your Table Is Located:
              </Typography>
              <Box
                sx={{
                  height: "330px",
                  overflow: "auto",
                }}
              >
                <img
                  src="./floorplan.png"
                  className={styles.floorplan}
                  style={{
                    width: "500px",
                  }}
                />
              </Box>
            </Grid>
            <Grid xs={12} md={6}                 
            sx={{
                  mt: "1px",
                  mb:"30px"
                }}>
              <Typography
                align="center"
                sx={{
                  fontWeight: 600,
                  fontSize: 20,
                  mb: 5,
         
                }}
              >
                Your Seat At The Table:
              </Typography>
              <YourTable

              />
            </Grid>
          </Grid>
        </Card>
      </Container>

      <Container
        sx={{
          pt: 3,
        }}
      >
        <Masonry
          columns={{ xs: 1, sm: 1, md: 2, lg:3 }}
          spacing={2}
          sx={{
            mt: 10,
          }}
        >
          {sittingTableData.map((gitem) => (
            <GuestCard gitem={gitem} subitem={gitem.subitem} />
          ))}
        </Masonry>
      </Container>
    </div>
  );
}

export default YourEvent;
