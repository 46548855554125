import React from "react";
import styles from "./Profile.module.css";
import { Container, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Unstable_Grid2";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Avatar from "@mui/material/Avatar";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ProfileEdit from "./ProfileEdit"
import Button from '@mui/material/Button';
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function Profile() {

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `profile-edit`; 
    navigate(path);
  }

  return (

    <div>
      <div className={styles.top}>
        <div className={styles.avatar}>
          <img alt="John Becker" src="./avatars/avatar-john.jpg" />
        </div>
      </div>
      <div className={styles.bottom}>
        <Container align="center">
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontSize: "20px",
              fontWeight: 700,
              mt: 6,
              mb: 2,
            }}
          >
            Add/Edit your Profile
          </Typography>

          <div component="div" className={styles.profileInfoContainer}>
            <div className={styles.profileInfo}>
              <div>Preferred (Tag) Name: </div>
              <div>JohnnyB </div>
            </div>

            <div className={styles.profileInfo}>
              <div>First Name: </div>
              <div>John</div>
            </div>
            <div className={styles.profileInfo}>
              <div>Middle Name: </div>
              <div>Albert</div>
            </div>

            <div className={styles.profileInfo}>
              <div>Last Name: </div>
              <div>Becker</div>
            </div>

            <div className={styles.profileInfo}>
              <div>Company: </div>
              <div className={styles.notProvided}>Not Provided</div>
            </div>

            <div className={styles.profileInfo}>
              <div>Profession: </div>
              <div className={styles.notProvided}>Not Provided</div>
            </div>



            <div className={styles.profileInfo}>
              <div>Facebook: </div>
              <div className={styles.notProvided}>Not Provided</div>
            </div>

            <div className={styles.profileInfo}>
              <div>Instagram: </div>
              <div className={styles.notProvided}>Not Provided</div>
            </div>

            <div className={styles.profileInfo}>
              <div>LinkedIn: </div>
              <div className={styles.notProvided}>Not Provided</div>
            </div>

            <div className={styles.profileInfo}>
              <div>Your Interest: </div>
              <div className={styles.notProvided}>Not Provided</div>
            </div>
          </div>

          <Typography
          
            align="left"
            sx={{
              fontSize: "14px",

              mt: 1,
              mb: 1,
            }}
          >
            You can add your interest, your facebook, instagram or LinkedIn
            profile links so people sitting at your table can get to know
            who you are.
          </Typography>

              <Button
                variant="contained"
                sx={{
                  mt: 2,
                }}
                onClick={routeChange}
              >
                Edit Profile
              </Button>
        </Container>
        <Container align="center">
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontSize: "20px",
              fontWeight: 700,
              mt: 6,
              mb: 2,
            }}
          >
            Register
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              mt: 1,
            }}
          >
            When you arrive, quick register at the entrance with this QR code
          </Typography>
          <div
            style={{
              border: "2px solid gray",
              width: "124px",
              height: "124px",
              marginTop: "12px",
            }}
          >
            <img src="./qr.png" height="120px" />
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Profile;
