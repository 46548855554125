// import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import React from "react";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import AccessibleIcon from '@mui/icons-material/Accessible';
import PeopleIcon from '@mui/icons-material/People';

export const LeftNavMenuData = [
  {
    title: "Register & Profile",
    path: "",
    icon: <PersonIcon />,
    cName: "",
  },
  {
    title: "Your Event",
    path: "/your-event",
    icon: <HomeOutlinedIcon />,
    cName: "classname ",
  },
  {
    title: "Event Timeline",
    path: "/event-timeline",
    icon: <ViewTimelineOutlinedIcon color="primary" />,
    cName: "classnameTimeLine ",
  },
  {
    title: "Up Coming Events",
    path: "/up-coming-events",
    icon: <EventNoteOutlinedIcon />,
    cName: "classname ", 
  },
  
  {
    title: "Useful Info",
    path: "/useful-info",
    icon: <InfoIcon />,
    cName: "classname ", 
  },
  {
    title: "Accessibility",
    path: "/Accessibility",
    icon: <AccessibleIcon />,
    cName: "classname ", 
  },
  {
    title: "About Us",
    path: "/about-us",
    icon: < PeopleIcon/>,
    cName: "classname ", 
  },

];