import React, { useState } from "react"; 
import styled from "@emotion/styled"; 
import { Box, Button, TextField } from '@mui/material'; 

const Text = styled.div` 
  font-size: 20px; 
  font-weight: bold; 
  margin: 10px; 
`; 

function PlayGround() {

    const [name, setName] = useState(''); 
    const [password, setPassword] = useState(''); 
  
    const handleClick = () => { 
        if (!name) { 
            alert(`Please Enter Name`) 
        } 
        else if (!password) { 
            alert(`Please Enter Password`) 
        } 
        else { 
            alert(`Thank you ${name} for Signing Up!`) 
        } 
    } 
    const handleChange = (event) => { 
        setName(event.target.value); 
    } 
    const handlePassword = (event) => { 
        setPassword(event.target.value); 
    }
    return (
        <Box sx={{ display: 'flex', alignItems: 'center',  
            justifyContent: 'center', height: '100vh' }}> 
            <Box sx={{ width: '200px', height: '400px',  
                boxShadow: 2, margin: '10px', display: 'flex',  
                flexDirection: 'column', alignItems: 'center' }}> 
                <Text>Sign Up</Text> 
                <TextField label='Name' variant="outlined" 
                    sx={{ margin: '10px' }} onChange={handleChange} /> 
                <TextField type='password' label='Password' 
                    variant="outlined" sx={{ margin: '10px' }}  
                    onChange={handlePassword} /> 
                <Button sx={{ width: '100px' }} variant="contained" 
                    onClick={handleClick}>Sign Up</Button> 
            </Box> 
        </Box> 
    )
}

export default PlayGround
