import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Container, Typography } from "@mui/material";
import styles from "./Profile.module.css";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';


function ProfileEdit() {

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `about-us`; 
    navigate(path);
  }

  return (
    <div>
      <div className={styles.top}>
        <div className={styles.avatar}>
          <img alt="John Becker" src="./avatars/avatar-john.jpg" />
        </div>
      </div>

      <div className={styles.bottom}>
        <Container
          align="center"
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "35ch" },
            // display: "flex",
            // flexFlow: "column",
            // textAlign: "center",
            border: "1px solid red",
            mb: 4,
            p: 4,
          }}
          noValidate
          autoComplete="off"
        >
          <Grid container rowSpacing={1}>
            <Grid xs={12}>
              <TextField
                id="standard-basic"
                label="Preferred (Tag) Name"
                variant="standard"
                size="normal"
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                id="standard-basic"
                label="First Name"
                variant="standard"
                size="normal"
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                id="standard-basic"
                label="Middle Name"
                variant="standard"
                size="normal"
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                id="standard-basic"
                label="Last Name"
                variant="standard"
                size="normal"
              />
            </Grid>

            <Grid xs={12}>
              <TextField
                id="standard-basic"
                label="Profession"
                variant="standard"
                size="normal"
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                id="standard-basic"
                label="Company"
                variant="standard"
                size="normal"
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                id="standard-basic"
                label="Instagram"
                variant="standard"
                size="normal"
              />
            </Grid>

            <Grid xs={12}>
              <TextField
                id="standard-basic"
                label="Facebook"
                variant="standard"
                size="normal"
              />
            </Grid>

            <Grid xs={12}>
              <TextField
                id="standard-basic"
                label="LinkedIn"
                variant="standard"
                size="normal"
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                id="standard-multiline-static"
                label="My Interest"
                multiline
                rows={4}
                variant="standard"
                placeholder="Placeholder"
              />
            </Grid>
          </Grid>

          <Stack
            direction='row'
            spacing={3}
            justifyContent='center'
            marginTop={4}

          >
            <Button
              variant="outlined"
              sx={{
                
              }}
              onClick={routeChange}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              sx={{
               
              }}
              onClick={routeChange}
            >
              Submit
            </Button>
          </Stack>
        </Container>
      </div>
    </div>
  );
}

export default ProfileEdit;
