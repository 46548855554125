import React from "react";
import styles from "./TableCircleTest.module.css";
import "./TableCircleTest.module.css";
import Avatar from "@mui/material/Avatar";
import { sittingTableData } from "../pages/YourEvent/sittingTableData";
import { BiBorderRadius } from "react-icons/bi";
import { Margin, Padding } from "@mui/icons-material";

const guestIndex = [1,2,3,4,5,6,7,8,9,10]
const rotateAngle = [0, 36, 72, 108,144,180, 216, 252, 288, 328]
const currentIndex = 0

function setIndex (i){
  return guestIndex[i]
  const currentIndex = 2
}
function TableCircleTest() {
  return (
    <div
      className={styles.circleContainer}
      style={{
        backgroundColor: "lightblue",
        position: "relative",
        width: "280px",
        height: "280px",
        borderRadius: "50%",
        padding: "0",
        margin: "5em auto 0",
        
        border: "1px solid grey",
        
      
      }}

    >
      {sittingTableData.map((item) => (
        <div item={item} subitem={item.subitem}> 

          <Avatar
            alt={item.firstName + " " + item.lastName}
            src={"avatars/" + item.avatar}
            className={styles.avatar}
            sx={{
              marginTop: "110px",
              marginLeft: "110px",
              width: "60px",
              height: "60px",
              position: "absolute",
             transform: "rotate("+rotateAngle[item.id]+"deg) translate(140px) rotate(-"+rotateAngle[item.id]+"deg)",
             border: "2px solid white",
             boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.4)"
            }}
           
          >

            
          </Avatar>
        </div>
      ))}
    </div>
  );
}

export default TableCircleTest;
