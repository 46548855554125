import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ButtonGroup from '@mui/material/ButtonGroup';
import styles from "./GuestCard.module.css";





function PickMedia({ mediaName, mediaAddress }) {
  if (mediaName === "facebook") {
    return <FacebookOutlinedIcon />;
  } else if (mediaName === "instagram") {
    return <InstagramIcon />;
  } else if (mediaName === "linkedin") {
    return <LinkedInIcon />;
  }
}

export default function GuestCard({ gitem }) {

  const isSeated = gitem.isSeated;
  return (
    <Card>
      <div className={styles.header} >

        <Avatar
          
          alt={gitem.firstName + " " + gitem.lastName}
          src={"avatars/"+gitem.avatar}
          className={styles.avatar}>

        </Avatar>

        <div>
          {isSeated &&
            <div className={styles.checkIconContainer}>
              <div>I'm here!</div>
              <CheckCircleOutlineIcon className={styles.checked} />
            </div>
          }

        </div>

      </div>

      <CardContent
        className={styles.cardContent}
        sx={{ mt: 2 }}
      >
        <Typography
          variant="h3"
          color="textPrimary"

          sx={{
            fontSize: '18px',
            fontWeight: 400
          }}
        >
          {gitem.firstName} {gitem.lastName}
        </Typography>

        <Typography
          variant="h4"
          // component="div"
          color="textSecondary"
          // className={styles.work}
          sx={{

            fontSize: 14,
            mt: 1
          }}
        >
          {gitem.work}
        </Typography>

        <Typography
          color="textSecondary"
          className={styles.position}
          sx={{
            fontStyle: "italic",
            fontSize: 12,

          }}
        >
          {gitem.position}
        </Typography>

        <Typography
          component="div"
          color="text.secondary"
          sx={{

            fontSize: 14,
            mt: 2

          }}
        >
          {gitem.aboutMe}

        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <div>
          {gitem.social.map((subitem, index) => {
            return (
              <ButtonGroup
                mt="40"
              >
                <IconButton
                  aria-label={subitem.mediaName}
                  href={subitem.address}
                  target="_blank"
                >
                  <PickMedia
                    mediaName={subitem.media}
                    mediaAddress={subitem.address}
                  />
                </IconButton>
              </ButtonGroup>
            );
          })}
        </div>
      </CardActions>
    </Card>

  );
}
